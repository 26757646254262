<input type="hidden" [formControl]="formCtrl" />
<button mat-icon-button class="w-24 h-24 mr-4" [matMenuTriggerFor]="selectColunas" [attr.aria-label]="tooltip" [matTooltip]="tooltip">
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu class="flex flex-col cursor-pointer h-{{calculaAltura()}}" cdkScrollbar [cdkScrollbarOptions]="{suppressScrollX: false, suppressScrollY: false, wheelPropagation: false, updateOnRouteChange: true}" #selectColunas="matMenu">
    <div class="flex flex-row gap-3 justify-start items-center content-center px-4" *ngFor="let element of elements" (click)="clickOption($event, element[idField])">
        <ng-container *ngIf="showOption(element)">
            <mat-icon *ngIf="multiple" class="text-24 w-24 h-24 leading-none">{{ formCtrl.value?.includes(element[idField]) ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
            <span class="flex-wrap my-2">{{ element[labelField] }}</span>
        </ng-container>
    </div>
    <mat-divider *ngIf="showReset"></mat-divider>
    <div *ngIf="showReset" class="flex flex-row gap-3 justify-start items-center content-center px-4" (click)="clickReset($event)">
        <mat-icon class="text-24 w-24 h-24 leading-none">list_alt</mat-icon>
        <span class="flex-wrap my-2">Redefinir Configurações</span>
    </div>
</mat-menu>

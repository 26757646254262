import {Observable, of} from 'rxjs';
import {Processo} from '@cdk/models';
import {filter, switchMap} from 'rxjs/operators';
// LIGAR COM O JUDICIAL
import {VinculadosModule} from "./app/main/apps/processo/vinculados/vinculados.module";

export const modulesConfigUtilPgers =
    {
        name: 'util-pgers',
        label: 'Util PGE-RS',
        generoProcesso: ['ADMINISTRATIVO'],
        routes: {
            'app/main/apps/admin': [
                {
                    path: 'mapeamento-distribuicao',
                    loadChildren: () => import(
                        './app/main/apps/admin/mapeamento-distribuicao/mapeamento-distribuicao.module'
                    ).then(m => m.MapeamentoDistribuicaoModule)
                },
                {
                    path: 'tipo-prazo',
                    loadChildren: () => import(
                        './app/main/apps/admin/tipo-prazo/tipo-prazo.module'
                    ).then(m => m.TipoPrazoModule)
                },
                {
                    path: 'tipo-audiencia',
                    loadChildren: () => import(
                        './app/main/apps/admin/tipo-audiencia/tipo-audiencia.module'
                    ).then(m => m.TipoAudienciaModule)
                },
                {
                    path: 'commits',
                    loadChildren: () => import(
                        './app/main/apps/admin/commits/commits.module'
                    ).then(m => m.CommitsModule)
                },
                {
                    path: 'advogados',
                    loadChildren: () => import(
                        './app/main/apps/advogados/advogados.module'
                    ).then(m => m.AdvogadosModule)
                }

            ],
            'app/main/apps/processo/processo-edit': [
                {
                    path: 'prazo',
                    loadChildren: () => import(
                        './app/main/apps/processo/prazo/prazo.module'
                    ).then(m => m.PrazoModule)
                },
                {
                    path: 'audiencia',
                    loadChildren: () => import(
                        './app/main/apps/processo/audiencia/audiencia.module'
                    ).then(m => m.AudienciaModule)
                }
            ],
        },
        mainMenu: [
            {
                id: 'admin',
                entries: [
                    {
                        id: 'tipo-audiencia',
                        title: 'Tipos de Audiências',
                        type: 'item',
                        icon: 'meeting_room',
                        url: '/apps/admin/tipo-audiencia'
                    },
                    {
                        id: 'tipo-prazo',
                        title: 'Tipos de Prazos',
                        type: 'item',
                        icon: 'alarm',
                        url: '/apps/admin/tipo-prazo'
                    },
                    {
                        id: 'mapeamento-distribuicao',
                        title: 'Mapeamento da Distrib. das Comunicações',
                        type: 'item',
                        icon: 'map',
                        url: '/apps/admin/mapeamento-distribuicao'
                    },
                    {
                        id: 'commits',
                        title: 'Commits',
                        type: 'item',
                        icon: 'alarm',
                        url: '/apps/admin/commits'
                    },
                ],
            },
        ],
        sidebars: {
            'app/main/apps/admin/sidebars/main': [
                {
                    nome: 'Tipos de Audiências',
                    icon: 'meeting_room',
                    link: 'tipo-audiencia',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Tipos de Prazos',
                    icon: 'alarm',
                    link: 'tipo-prazo',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Mapeamento da Distrib. das Comunicações',
                    icon: 'map',
                    link: 'mapeamento-distribuicao',
                    role: 'ROLE_ADMIN'
                },
                {
                    nome: 'Commits',
                    icon: 'list',
                    link: 'commits',
                    role: 'ROLE_ADMIN'
                }
            ],
            'app/main/apps/processo/processo-edit/sidebars/main': [
                {
                    index: 25,
                    nome: 'Audiências PGE',
                    link: 'editar/audiencia',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado || (
                                processo.especieProcesso.nome !== 'DOSSIÊ JUDICIAL' &&
                                processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' &&
                                processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')
                                ) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
                },
                {
                    index: 26,
                    nome: 'Prazos PGE',
                    link: 'editar/prazo',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
                },
            ],
        },
        routerLinks: {},
        components: {
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': [
                () => import ('modules/util-pgers/app/main/apps/tarefas/data-prazo/data-prazo.module').then(m => m.DataPrazoModule),
                () => import ('modules/util-pgers/app/main/apps/tarefas/data-audiencia/data-audiencia.module').then(m => m.DataAudienciaModule),
            ],
             // LIGAR COM O JUDICIAL
            'app/main/apps/processo/processo-capa/balao': [
                () => import ('modules/util-pgers/app/main/apps/processo/vinculados/vinculados.module').then(m => m.VinculadosModule),
            ],
        },
        ajuda: [],
        widgets: [],
        generoAffinity: {
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': {
                'ADMINISTRATIVO': [],
                'CONSULTIVO': [],
                'CORREICIONAL': [],
                'JUDICIAL': [],
                'DIVIDA': [],
            },
            'app/main/apps/processo/processo-capa/balao': {
                'ADMINISTRATIVO': [],
                'CONSULTIVO': [],
                'CORREICIONAL': [],
                'JUDICIAL': [],
                'DIVIDA': [],
            },
        },
        navigationConverter: {
            mainMenu: {}
        }
    };
